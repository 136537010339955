import '../styles/views/Home.scss';
import { useState, useEffect, useCallback, useRef, createRef, PureComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import localization from '../localization';
import Pusher from 'pusher-js';
import moment from '../moment.js';
import { isMobile } from 'react-device-detect';
import { FaTimes } from 'react-icons/fa';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiOutlineClose, AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { TbZoomReset } from 'react-icons/tb';
import { FcPlus, FcLock, FcCancel } from 'react-icons/fc';
import { IoIosArrowDropdown } from 'react-icons/io';
import { Lightbox } from "react-modal-image";
import { CgClose } from 'react-icons/cg';
import homeLogo from '../images/i68-icon.png';
import man from '../images/man.png';
import woman from '../images/woman.png';
import plus from '../images/plus.png';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import HeaderNav from './HeaderNav';
import Header from './Header';
import FooterNav from './FooterNav';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import { FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let pusher = new Pusher('b534d4fac76717b9872e', {
  cluster: 'us2',
  encrypted: true,
});

let channel = pusher.subscribe('channel');

// https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

class KeyEventAdapter extends PureComponent {
  
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
  
  onKeyDown = (e) => {
    if (e.code === 'ArrowRight') {
      let currentIndex = this.props.locations.findIndex(location => location.locationId === this.props.selectedLocation.locationId);
      console.log('currentIndex', currentIndex)
      if (this.props.locations[currentIndex + 1]) {
        this.props.setSelectedLocation(this.props.locations[currentIndex + 1]);
      } else {
        // setSelectedLocation(this.props.locations[0]);
      }
    } else if (e.code === 'ArrowLeft') {
      let currentIndex = this.props.locations.findIndex(location => location.locationId === this.props.selectedLocation.locationId);
      if (currentIndex - 1 >= 0) {
        this.props.setSelectedLocation(this.props.locations[currentIndex - 1]);
      } else {
        // setSelectedLocation(this.props.locations[locations.length - 1]);
      }
    }
  };
  
  render() {
    return (
      <></>
    );
  }
  
}

function Home({ user, logout }) {
  
  let navigate = useNavigate();
  const [error, setError] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [activeLocation, setActiveLocation] = useState({});
  const [increment, setIncrement] = useState(1); // Force Re-Render
  const [warning, setWarning] = useState('');
  let [selectedDate, setSelectedDate] = useState(moment.tz().format('YYYY-MM-DD'));
  let [daysAdded, setDaysAdded] = useState(0);
  let [groups, setGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState('');
  const cookies = new Cookies();
  const [currentPageName, setCurrentPageName] = useState('home');
  const menuRef = useRef(null);
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState(false);
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(moment.tz().format('MMMM, YYYY'));
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  
  const bodyRef = useRef(null);
  
  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => {
      if (e.event.target.classList.contains('slide') || e.event.target.classList.contains('image-slider-wrapper')) {
        return;
      }
      let currentIndex = locations.findIndex(location => location.locationId === selectedLocation.locationId);
      if (locations[currentIndex + 1]) {
        setSelectedLocation(locations[currentIndex + 1]);
      } else {
        // setSelectedLocation(locations[0]);
      }
    },
    onSwipedRight: (e) => {
      if (e.event.target.classList.contains('slide') || e.event.target.classList.contains('image-slider-wrapper')) {
        return;
      }
      let currentIndex = locations.findIndex(location => location.locationId === selectedLocation.locationId);
      if (currentIndex - 1 >= 0) {
        setSelectedLocation(locations[currentIndex - 1]);
      } else {
        // setSelectedLocation(locations[locations.length - 1]);
      }
    },
  });
  
  // useEffect(() => {
    
  //   document.addEventListener('keydown', onKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', onKeyDown);
  //   }
  // }, []);
  
  // const dateSwipeHandlers = useSwipeable({
  //   onSwipedLeft: (e) => {
  //     console.log(e);
  //   },
  //   onSwipedRight: (e) => {
  //     console.log(e);
  //   },
  // });
  
  useEffect(() => {
    
    if (!user.userId) {
      return;
    }
    
    fetchGroups();
    
  }, [user]);

  const adminPanel = () => navigate('/admin/users');
  if (!user) {
    return;
  }

  const groupPanel = () => navigate('/admin/groups');
  if (!user) {
    return;
  }

  const myShift = () => navigate('/myshift');
  
  const handleGroupChange = (value) => {
    setSelectedGroupId(Number(value));
    fetchLocationsOfGroups(Number(value));
    fetchGroupUsers(Number(value));
  };
  
  const onGroupChange = (groupId) => {
    setSelectedGroupId(groupId);
    fetchLocationsOfGroups(groupId);
    fetchGroupUsers(groupId);
  };

  const fetchLocationsOfGroups = async (groupId) => {
    try {
      let response = await (
        await window.fetch('/api/groups/locations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            groupId: groupId,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      let tempLocations = response.data;
      tempLocations.forEach((item) => {
        if (item.imagePath && item.imagePath != 'null') {
          let tempImagePath = item.imagePath.split(',');
          tempImagePath = tempImagePath.filter((n) => n);
          item.imagePath = tempImagePath;
        } else {
          item.imagePath = [];
        }
      });
      setLocations(tempLocations);
      setSelectedLocation(tempLocations[0]);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  const fetchGroups = async () => {
    try {
      if (selectedGroupId) {
        return;
      }
      let response = await (
        await window.fetch('/api/groups/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      setGroups(response.data);
      if (user.groups[0]) {
        setSelectedGroupId(user.groups[0]);
        fetchLocationsOfGroups(user.groups[0]);
        fetchGroupUsers(user.groups[0]);
      } else if (response.data[0]) {
        setSelectedGroupId(response.data[0].groupId);
        fetchLocationsOfGroups(response.data[0].groupId);
        fetchGroupUsers(response.data[0].groupId);
      }
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };
  
  
  
  const fetchGroupUsers = async (groupId) => {
    try {
      let response = await (
        await window.fetch('/api/admin/groups/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.userId,
            groupId: groupId,
            adminType: user.admin,
          }),
          credentials: 'include',
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      let tempUsers = response.data.filter(
        (item) => item.userId != user.userId
      );
      setGroupUsers(tempUsers);
    } catch (err) {
      setWarning(localization['Something went wrong']);
    }
  };

  // const setLanguage = async (lang) => {
  //   try {
  //     let response = await (
  //       await window.fetch('/api/admin/users/set-language', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           userId: user.userId,
  //           language: lang,
  //         }),
  //         credentials: 'include',
  //       })
  //     ).json();
  //     if (response.status !== 'success') {
  //       throw Error(response.message);
  //     } else {
  //       user.language = lang;
  //     }
  //   } catch (err) {
  //     setWarning(localization['Something went wrong']);
  //   }
  // };
  
  const showHamburgerMenu = () => {
    setHamburgerMenuActive(!hamburgerMenuActive);
    // menuRef.current.click();
  };
  
  const handleLocationIdChange = (locationId) => {
    setSelectedLocationId(locationId);
    console.log('locations.find(location => location.locationId === locationId)', locations.find(location => location.locationId === locationId))
    setSelectedLocation(locations.find(location => location.locationId === Number(locationId)));
  };
  
  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };
  
  const handleMonthAndYearChange = (monthAndYear) => {
    setSelectedMonthAndYear(monthAndYear);
  };
  
  return (
    <div className="Home pt-12" {...swipeHandlers}>
      {/*
      //commented old section 
      <Nav variant="tabs" onSelect={handleSelect}>
      {locations.map(item => {
        return (
          <Nav.Item key={item.id}>
            <Nav.Link  active={activeLocation.locationId == item.locationId} eventKey={item.locationId}>{item.name}</Nav.Link>
          </Nav.Item>
        );
      })}
      </Nav>
      {activeLocation.locationId && 
        <LocationTable activeLocation={activeLocation} user={user}/>
      }
      //old section end
      */}
      {/* new design */}
      <div className="home-wrapper actual-home-wrapper">
        <Header 
          user={user}
          logout={logout}
          currentPage={currentPageName}
          showHamburgerMenu={showHamburgerMenu}
          hamburgerMenuActive={hamburgerMenuActive}
          onGroupChange={onGroupChange}
        />
        <nav className="navbar pb-0">
          <div className="nav-container">
            <HeaderNav
              user={user}
              logout={logout}
              showHamburgerMenu={showHamburgerMenu}
              hamburgerMenuActive={hamburgerMenuActive}
            />
          </div>
        </nav>
        <div className="">
          {/*<nav className="navbar">
            <div className="container nav-container">
              <input
                className="checkbox"
                type="checkbox"
                name=""
                id="chk_ham"
                ref={menuRef}
              />
              <label htmlFor="chk_ham"></label>
              <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>

              <HeaderNav
                user={user}
                logout={logout}
                showHamburgerMenu={showHamburgerMenu}
                hamburgerMenuActive={hamburgerMenuActive}
              />
            </div>
          </nav>*/}
          {/* not used any more from here */}
          <div className="d-flex justify-content-end mb-3 p-0 d-none">
            {(user.admin === 1 || user.admin === 2) && (
              <Form.Select
                className=""
                title={localization['Choose Group']}
                value={selectedGroupId}
                onChange={(e) => handleGroupChange(e.target.value)}
              >
                {groups.map((item, i) => {
                  return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
                })}
              </Form.Select>
            )}
            {(user.admin === 0 || user.admin === 3) && (
              <Form.Select
                className=""
                title={localization['Choose Group']}
                value={selectedGroupId}
                onChange={(e) => handleGroupChange(e.target.value)}
              >
                {groups.filter(group => user.groups.includes(group.groupId)).map((item, i) => {
                  return <option key={item.groupId} value={item.groupId}>{item.groupName}</option>;
                })}
              </Form.Select>
            )}
            {/*<Nav variant="pills">
              <Nav.Item>
                <Form.Select
                  className="d-inline-block w-auto align-middle me-2 language-dropdown"
                  onChange={(e) => {
                    let language = e.target.value;
                    localization.setCookieLanguage(language);
                    setIncrement(increment + 1);
                    // formatDate();
                    setLanguage(language);
                  }}
                >
                  {localization.getAvailableLanguages().map((language) => {
                    return (
                      <option
                        key={language}
                        value={language}
                        selected={localization.getLanguage() === language}
                      >
                        {language.toUpperCase()}
                      </option>
                    );
                  })}
                </Form.Select>
              </Nav.Item>
            </Nav>*/}
          </div>

          <div>
            {/*<img
              src={homeLogo}
              alt="i68"
              className="home-button"
            />
            <div className="name-location mt-2">
              <strong>
                <span
                  className={user.gender == 2 ? 'female-color' : 'male-color'}
                >
                  {user.name}
                </span>
                {{groupsNames.length > 0 && groupsNames.map((groupName, i) => {
                  return (
                    <span> {groupName}{groupsNames[i + 1] ? ',' : ''}</span>
                  );
                })}
              </strong>
            </div>*/}
            <div>
              {/*
              <LocalizationProvider 
                dateAdapter={AdapterDayjs}
                localeText={{
                  cancelButtonLabel: localization['Cancel'], 
                  okButtonLabel: localization['Change'],
                  openDatePickerDialogue: (a, b, c) => {
                    console.log(a, b, c)
                  },
                  getLabelText: () => {
                    console.log('heeey')
                  }
                }}
                // localeText={ukUA.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  value={selectedDate}
                  closeOnSelect={true}
                  ampm={false}
                  minDate={new Date()}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setSelectedDate(moment.tz(new Date(newValue)).format('YYYY-MM-DD'));
                  }}
                  renderInput={(params) => {
                    if (isMobile) {
                      params.className = 'w-100';
                    }
                    params.inputProps.value = `${localization[moment.tz(selectedDate).format('dddd')]}, ${localization[moment.tz(selectedDate).format('MMMM')]} ${moment.tz(selectedDate).format('D, YYYY')}`
                    return (
                      <div className="react-datepicker-ignore-onclickoutside p-1 pt-0">
                        <TextField
                          onClick={(e => {
                            //if (!isMobile) {
                              document.querySelector('button.MuiButtonBase-root').click();
                           // }
                          })}
                          className="text-input h3"
                          readOnly
                          {...params}
                        />
                      </div>
                    )
                  }}
                />
              </LocalizationProvider>
              */}
              {/*
              <div className="horizontal-scroll">
                {Array.apply(0, Array(36)).map(function(_,i){
                  let month = moment.tz().month(i).format('MMMM');
                  let year = moment.tz().year(2023 + Math.floor(i / 12)).format('YYYY');
                  return (
                    <div className={`d-inline-block ${selectedMonthAndYear === `${month}, ${year}`} ? 'active' : ''`}>
                      {`${localization[month]}, ${year}`}
                    </div>  
                  )
                  
                })}
              </div>
              */}
            </div>
            {/*
            <div className="header-buttons-wrapper">
              <button
                className="mx-1 btn px-3 btn-arrow"
                onClick={(e) => setSelectedDate(moment.tz(selectedDate).subtract(7, 'days').format('YYYY-MM-DD'))}
                title={localization['Minus 7 Days']}
              >
                &lt;&lt;
              </button>
              <button
                className="mx-1 btn px-3 btn-arrow"
                onClick={(e) => setSelectedDate(moment.tz(selectedDate).subtract(1, 'days').format('YYYY-MM-DD'))}
                title={localization['Previous Day']}
              >
                &lt;
              </button>
              <button
                className="mx-1 btn btn-primary px-3"
                onClick={(e) => setSelectedDate(moment.tz(new Date()).format('YYYY-MM-DD'))}
              >
                {localization['Today']}
              </button>
              <button
                className="mx-1 btn px-3 btn-arrow"
                onClick={(e) => setSelectedDate(moment.tz(selectedDate).add(1, 'days').format('YYYY-MM-DD'))}
                title={localization['Next Day']}
              >
                &gt;
              </button>
              <button
                className="mx-1 btn px-3 btn-arrow"
                onClick={(e) => setSelectedDate(moment.tz(selectedDate).add(7, 'days').format('YYYY-MM-DD'))}
                title={localization['Plus 7 Days']}
              >
                &gt;&gt;
              </button>
            </div>
            */}
            {/*
            <div className="horizontal-scroll">
              <div>
                {Array.apply(0, Array(36)).map((_,i) => {
                  let month = moment.tz().month(i).format('MMMM');
                  let year = moment.tz().year(2023 + Math.floor(i / 12)).format('YYYY');
                  return (
                    <div className={`d-inline-block ${selectedMonthAndYear === `${month}, ${year}`} ? 'active' : ''`}>
                      {`${localization[month]}, ${year}`}
                    </div>  
                  )
                  
                })}
              </div>
            </div>
            */}
            <div className="mt-1 mb-2">
              <Form.Select
                size="lg"
                className="border-0 font-weight-bold"
                style={{
                  fontSize: '22px'
                }}
                value={selectedMonthAndYear}
                onChange={(e) => handleMonthAndYearChange(e.target.value)}
              >
                {Array.apply(0, Array(36)).map((_, i) => {
                  let month = moment.tz().month(i).format('MMMM');
                  let year = moment.tz().year(2024 + Math.floor(i / 12)).format('YYYY');
                  return (
                    <option key={`${month}, ${year}`} value={`${month}, ${year}`} selected={selectedMonthAndYear === `${month}, ${year}`}>
                      {`${localization[month]}, ${year}`}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            {!isMobile &&
              <KeyEventAdapter
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            }
            {/*!isMobile &&
              <div className="d-flex">
                <Form.Select
                  className="mb-2"
                  title={localization['Choose Location']}
                  value={selectedLocationId}
                  onChange={(e) => handleLocationIdChange(e.target.value)}
                >
                  {locations.map((location, i) => {
                    return <option key={location.locationId} value={location.locationId}>{location.name}</option>;
                  })}
                </Form.Select>
              </div>
            */}
            {selectedLocation &&
              <div
                className="location-header d-flex align-items-center"
                style={{
                  backgroundColor:
                    selectedLocation.bgColor != 'null' ? selectedLocation.bgColor : null,
                }}
              >
                <h4 className="text-nowrap m-0">{selectedLocation.name}</h4>
                <FormGroup className="">
                  <Switch color="default" checked={showImages} onChange={e => {
                    setShowImages(!showImages);
                  }} />
                </FormGroup>
                <div className="description" style={{
                  display: showImages ? 'block' : 'none'
                }}>
                  {selectedLocation.description}
                  {selectedLocation && selectedLocation.imagePath && selectedLocation.imagePath.length > 0 && (
                    <ImageSlider data={selectedLocation.imagePath} />
                  )}
                </div>
              </div>
            }
          </div>
        </div>
        <section className="home-body actual-home-body mt-3" ref={bodyRef}>
          {/*
          <ul>
            {locations..map((item, i) => {
              return (
                <li key={item.locationId}>
                  <div
                    className="location-header"
                    style={{
                      backgroundColor:
                        item.bgColor != 'null' ? item.bgColor : null,
                    }}
                  >
                    <h4 className="text-nowrap">{item.name}</h4>
                    <FormGroup className="">
                      <Switch color="default" checked={item.showImages} onChange={e => {
                        let newLocations = [...locations];
                        newLocations[i].showImages = !newLocations[i].showImages;
                        setLocations(newLocations);
                      }} />
                    </FormGroup>
                    <div className="description" style={{
                      display: item.showImages ? 'block' : 'none'
                    }}>
                      {item.description}
                      {item.imagePath.length > 0 && (
                        <ImageSlider data={item.imagePath} />
                      )}
                    </div>
                  </div>
                  <LocationTable
                    activeLocation={activeLocation}
                    updateLocation={e => onGroupChange(selectedGroupId)}
                    user={user}
                    locationId={item.locationId}
                    selectedGroupId={selectedGroupId}
                    selectedDate={selectedDate}
                  />
                </li>
              );
            })}
          </ul>
          */}
          <LocationTable
            activeLocation={activeLocation}
            updateLocation={e => onGroupChange(selectedGroupId)}
            user={user}
            location={selectedLocation}
            selectedGroupId={selectedGroupId}
            selectedLocationId={selectedLocationId}
            selectedDate={selectedDate}
            selectedMonthAndYear={selectedMonthAndYear}
            bodyRef={bodyRef}
            groupUsers={groupUsers}
          />
        </section>
        <footer>
          <FooterNav
            user={user}
            logout={logout}
            currentPage={currentPageName}
            showHamburgerMenu={showHamburgerMenu}
            hamburgerMenuActive={hamburgerMenuActive}
          />
        </footer>
      </div>
    </div>
  );
}

function ImageSlider({ data }) {
  const [previewImage, setPreviewImage] = useState('');
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [previousEndX, setPreviousEndX] = useState(0);
  const [currentPositionX, setCurrentPositionX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endY, setEndY] = useState(0);
  const [previousEndY, setPreviousEndY] = useState(0);
  const [currentPositionY, setCurrentPositionY] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [previewImageHeight, setPreviewImageHeight] = useState(0);
  const [previewImageWidth, setPreviewImageWidth] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const containerRef = useRef();
  const indicatorRef = useRef();

  // const updateImagePosition = () => {
  //   console.log(imageRefs[previewImage].current, containerRef.current)
    
  //   console.log('maxWidth', maxWidth)
  //   console.log('Math.max(Math.min(currentPosition, 0), -maxWidth)', Math.max(Math.min(currentPosition, 0), -maxWidth))
  //   setCurrentPosition(Math.max(Math.min(currentPosition, 0), -maxWidth));
  // }

  // useEffect(() => {
  //   if (!previewImage || !imageRefs[previewImage] || !containerRef) {
  //     return;
  //   }
  //   const maxWidth = imageRefs[previewImage].current.naturalWidth - containerRef.current.clientWidth;
  //   let newCurrentPosition = Math.max(Math.min(currentPosition, 0), -maxWidth);
  //   console.log('currentPosition', currentPosition);
  //   console.log('maxWidth', maxWidth);
  //   console.log('newCurrentPosition', newCurrentPosition);
  //   if (newCurrentPosition != currentPosition) {
  //     setCurrentPosition(newCurrentPosition);
  //   }
  // }, [currentPosition]);

  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [indicatorHeight, setIndicatorHeight] = useState(0);
  
  useEffect(() => {
    if (indicatorRef.current) {
      setIndicatorWidth(indicatorRef.current.clientWidth);
      setIndicatorHeight(indicatorRef.current.clientHeight);
    }
  }, [previewImageHeight, previewImageWidth]);

  return (
    <div 
      className="carousel mt-2"
      ref={containerRef}
    >
      <Carousel
        showArrows={previewImage ? false : true}
        showIndicators={previewImage ? false : true}
        swipeable={previewImage ? false : true}
        showStatus={false}
        onChange={(index) => {
          setActiveImage(index);
        }}
      >
        {data.map((image, i) => {
          return (
            <div className="image-slider-wrapper" style={{
              width: previewImage && previewImage === image ? 'fit-content' : '100%',
              height: previewImage && previewImage === image ? 'fit-content' : '100%',
            }} key={image} onClick={e => {
              // if (!previewImage) {
              //   setPreviewImage(image);
              //   setZoomLevel(zoomLevel + 0.2);
              //   // document.body.style.overscrollBehavior = 'contain';
              //   setCurrentPositionX(-((imageRefs[image].current.naturalWidth - containerRef.current.clientWidth) / 2));
              //   setPreviousEndX(-((imageRefs[image].current.naturalWidth - containerRef.current.clientWidth) / 2));
              //   setCurrentPositionY(-((imageRefs[image].current.naturalHeight - containerRef.current.clientHeight) / 2));
              //   setPreviousEndY(-((imageRefs[image].current.naturalHeight - containerRef.current.clientHeight) / 2));
              //   setPreviewImageHeight(imageRefs[image].current.naturalHeight);
              //   setPreviewImageWidth(imageRefs[image].current.naturalWidth);
              // } else {
              //   setPreviewImage('');
              //   // document.body.style.overscrollBehavior = 'iniital';
              //   setStartX(0);
              //   setEndX(0);
              //   setCurrentPositionX(0);
              //   setPreviousEndX(0);
              //   setStartY(0);
              //   setEndY(0);
              //   setCurrentPositionY(0);
              //   setPreviousEndY(0);
              // }
            }} onTouchStart={e => {
              if (previewImage) {
                // e.preventDefault();
                setStartX(e.touches[0].clientX);
                setStartY(e.touches[0].clientY);
              }
            }} onTouchMove={e => {
              if (previewImage) {
                e.preventDefault();
                // e.stopPropagation();
                if (startX) {
                  const currentX = e.touches[0].clientX;
                  console.log('currentX', currentX);
                  const deltaX = currentX - startX;
                  console.log('deltaX', deltaX);
                  console.log('previousEndX', previousEndX)
                  setCurrentPositionX(previousEndX + deltaX);
                  setEndX(previousEndX + deltaX);
                }
                if (startY) {
                  const currentY = e.touches[0].clientY;
                  console.log('currentY', currentY);
                  const deltaY = currentY - startY;
                  console.log('deltaY', deltaY);
                  console.log('previousEndY', previousEndY)
                  setCurrentPositionY(previousEndY + deltaY);
                  setEndY(previousEndY + deltaY);
                }
              }
            }} onTouchEnd={e => {
              if (previewImage) {
                // e.preventDefault();
                setPreviousEndX(endX);
                setPreviousEndY(endY);
              }
            }} onMouseDown={e => {
              console.log(e);
              if (previewImage && !mouseDown) {
                setMouseDown(true);
                // e.preventDefault();
                setStartX(e.clientX);
                setStartY(e.clientY);
              }
            }} onMouseMove={e => {
              if (previewImage && mouseDown) {
                e.preventDefault();
                // e.stopPropagation();
                if (startX) {
                  const currentX = e.clientX;
                  console.log('currentX', currentX);
                  const deltaX = currentX - startX;
                  console.log('deltaX', deltaX);
                  console.log('previousEndX', previousEndX)
                  setCurrentPositionX(previousEndX + deltaX);
                  setEndX(previousEndX + deltaX);
                }
                if (startY) {
                  const currentY = e.clientY;
                  console.log('currentY', currentY);
                  const deltaY = currentY - startY;
                  console.log('deltaY', deltaY);
                  console.log('previousEndY', previousEndY)
                  setCurrentPositionY(previousEndY + deltaY);
                  setEndY(previousEndY + deltaY);
                }
              }
            }} onMouseUp={e => {
              if (previewImage && mouseDown) {
                setMouseDown(false);
                // e.preventDefault();
                setPreviousEndX(endX);
                setPreviousEndY(endY);
              }
            }} onMouseLeave={e => {
              if (previewImage && mouseDown) {
                setMouseDown(false);
                setPreviousEndX(endX);
                setPreviousEndY(endY);
              }
            }}>
              <img className={`${previewImage && previewImage === image ? 'previewed-image' : ''}`} src={image} style={{
                width: previewImage && previewImage === image ? `${100 * zoomLevel}%`: '100%',
                height: previewImage && previewImage === image ? `${100 * zoomLevel}%` : '100%',
                maxHeight: 'auto !important',
                maxWidth: 'auto !important',
                // objectFit: previewImage && previewImage === image ? 'none' : 'contain',
                objectFit: 'contain',
                transform: previewImage && previewImage === image ? `translateX(${currentPositionX}px) translateY(${currentPositionY}px)` : '',
                transition: 'transform 0.1s linear'
              }} />
            </div>
          )
        })}
      </Carousel>
      {zoomLevel !== 1 &&
        <div className="icon" style={{
          position: 'absolute',
          top: '-36px',
          right: '72px',
          // pointerEvents: 'none',
          // padding: '8px'
        }} onClick={() => {
          setZoomLevel(1);
          // containerRefs[previewImage].current.click();
          setPreviewImage('');
          // document.body.style.overscrollBehavior = 'iniital';
          setStartX(0);
          setEndX(0);
          setCurrentPositionX(0);
          setPreviousEndX(0);
          setStartY(0);
          setEndY(0);
          setCurrentPositionY(0);
          setPreviousEndY(0);
        }}>
          <button className="btn btn-outline-white p-1">
          <TbZoomReset className="text-white" size="1.5rem" />
          </button>
        </div>
      }
      {zoomLevel !== 1 &&
        <div className="icon" style={{
          position: 'absolute',
          top: '-36px',
          right: '36px',
          // pointerEvents: 'none',
          // padding: '8px'
        }} onClick={() => {
          setZoomLevel(zoomLevel - 0.2);
          if (zoomLevel - 0.2 === 1) {
            // containerRefs[previewImage].current.click();
            setPreviewImage('');
            // document.body.style.overscrollBehavior = 'iniital';
            setStartX(0);
            setEndX(0);
            setCurrentPositionX(0);
            setPreviousEndX(0);
            setStartY(0);
            setEndY(0);
            setCurrentPositionY(0);
            setPreviousEndY(0);
          }
        }}>
          <button className="btn btn-outline-white p-1">
          <AiOutlineZoomOut className="text-white" size="1.5rem" />
          </button>
        </div>
      }
      <div className="icon" style={{
        position: 'absolute',
        top: '-36px',
        right: '0px',
        // pointerEvents: zoomLevel !== 1 ? 'all' : 'none',
        // padding: '8px'
      }} onClick={() => {
        setZoomLevel(zoomLevel + 0.2);
        if (!previewImage) {
          // containerRefs[previewImage].current.click();
                setPreviewImage(data[activeImage]);
                setZoomLevel(zoomLevel + 0.2);
                // document.body.style.overscrollBehavior = 'contain';
                setCurrentPositionX(-((containerRef.current.clientWidth * (zoomLevel + 0.2) - containerRef.current.clientWidth) / 2));
                setPreviousEndX(-((containerRef.current.clientWidth * (zoomLevel + 0.2) - containerRef.current.clientWidth) / 2));
                setCurrentPositionY(-((containerRef.current.clientHeight * (zoomLevel + 0.2) - containerRef.current.clientHeight) / 2));
                setPreviousEndY(-((containerRef.current.clientHeight * (zoomLevel + 0.2) - containerRef.current.clientHeight) / 2));
                setPreviewImageHeight(containerRef.current.clientHeight * (zoomLevel + 0.2));
                setPreviewImageWidth(containerRef.current.clientWidth * (zoomLevel + 0.2));
        }
      }}>
        <button className="btn btn-outline-white p-1">
        <AiOutlineZoomIn className="text-white" size="1.5rem" />
        </button>
      </div>
      {/*
      <div style={{
        width: '80px',
        height: 'auto',
        border: '1px solid white',
        opacity: '0.8',
        position: 'absolute',
        right: '6px',
        bottom: '6px',
        display: previewImage ? 'block' : 'none'
      }} className="image-position-indicator" ref={indicatorRef}>
        {previewImage &&
          <>
            <img style={{
              objectFit: 'contain'
            }} src={previewImage}/>
            <div style={{
              position: 'absolute',
              border: '2px solid white',
              width: `${indicatorWidth / 100 * (100 / previewImageWidth * containerRef.current.clientWidth) + 2}px`,
              height: `${indicatorHeight / 100 * (100 / previewImageHeight * containerRef.current.clientHeight) + 2}px`,
              left: `${indicatorWidth / 100 * (100 / previewImageWidth * Math.abs(currentPositionX)) - 4}px`,
              top: `${indicatorHeight / 100 * (100 / previewImageHeight * Math.abs(currentPositionY)) - 4}px`,
            }} className="image-frame-indicator"></div>
          </>
        }
      </div>
      */}
      {/* {previewImage &&
        <Lightbox
          medium={previewImage}
          large={previewImage}
          alt=""
          onClose={e => {
            console.log('closing')
          }}
          hideDownload={true}
          hideZoom={true}

        />
      } */}
    </div>
  );
}

function LocationTable({
  activeLocation,
  updateLocation,
  user,
  location,
  selectedGroupId,
  selectedDate,
  selectedMonthAndYear,
  selectedLocationId,
  bodyRef,
  groupUsers
}) {
  
  const [slots, setSlots] = useState([]);
  // const [groupName, setGroupName] = useState('');
  // const [groupsNames, setGroupsNames] = useState([]);
  const [warning, setWarning] = useState('');
  
  const [getSlotsController, setGetSlotsController] = useState(null);
  const [resetScroll, setResetScroll] = useState(true);
  const [currentScroll, setCurrentScroll] = useState(0);
  
  const [slotRefs, setSlotRefs] = useState([]);
  // const currentDateRef = useRef();
  
  const removeAfterExpiration = async (slotId) => {
    let slotIds = [];
    slotIds.push(slotId);
    
    if (!window.confirm(`${localization['Are you sure? \nThis action will delete the historical record for users previously at this location/ time/day on their ‘Assignments’ page.']}`)) {
      return;
    }
    
    try {
      let response = await (
        await window.fetch('/api/admin/slots/remove', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotIds: slotIds,
            userId: user.userId,
            expired: true, // so that will not be shown in home/myshift
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      } else {
        getSlots();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = async (e, slotId, description, date, sTime, userId) => {
    
    if (!window.confirm(localization['Are you sure you want to cancel this slot?'])) {
      return;
    }
    
    if (!resetScroll) {
      setCurrentScroll(bodyRef.current.scrollTop);
    }
    
    // e.target.disabled = true;
    let hours = moment.duration(moment.utc().diff(moment.utc(sTime))).asHours();
    console.log('hours', hours)
    console.log(moment.utc(), moment.utc(sTime))
    console.log(moment.duration(moment.utc().diff(moment.utc(sTime))))
    // if (user.admin === 0 && hours <= 2) {
    //   alert(localization['Cannot cancel as less than 2 hours left']);
    //   e.target.disabled = false;
    //   return;
    // }
    
    try {
      
      let response = await (
        await window.fetch('/api/slot/cancel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotId: slotId,
            userId: userId,
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      getSlots();
      e.target.disabled = false;
    } catch (err) {
      console.log(err);
    }
  };
  
  const reserve = async (slotId, userId) => {
    
    if (!window.confirm(localization['Do you want to book this slot?\n(It will be possible to cancel the duty no later than 2 hours before it starts)'])) {
      return;
    }
    
    if (!resetScroll) {
      setCurrentScroll(bodyRef.current.scrollTop);
    }
    
    try {
      let response = await (
        await window.fetch('/api/slot/request', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            slotId: slotId,
            userId: userId
          }),
        })
      ).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      getSlots();
    } catch (err) {
      console.log(err);
    }
    
  };
  
  const callRequest = (e) => {
    if (window.confirm(localization['Do you want to call this person?'])) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };

  let pressTimer = null;
  const callMouseUp = (e) => {
    clearTimeout(pressTimer);
  };
  
  const callMouseDown = (e) => {
    // Set timeout
    pressTimer = window.setTimeout(function () {
      callRequest(e);
    }, 1000);
    return false;
  };

  const getSlots = async () => {
    try {
      
      setSlots([]);
      
      if (getSlotsController) {
        getSlotsController.abort();
      }
      
      let date = moment.tz().utc().format('YYYY-MM-DD');
      
      const controller = new AbortController();
      const signal = controller.signal;
      setGetSlotsController(controller);
      
      let responseSlot = await (
        await window.fetch('/api/slots/home', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          signal: signal,
          body: JSON.stringify({
            locationId: location.locationId,
            userId: user.userId,
            date: date,
            monthAndYear: selectedMonthAndYear
          }),
        })
      ).json();
      
      // let isDST = moment.tz().isDST();
      
      let slots = [];
      for (let i in responseSlot.data) {
        let item = responseSlot.data[i];
        
        let slotIndex = slots.findIndex(slot => slot.sTime === item.sTime && slot.eTime === item.eTime);
        
        if (slotIndex === -1) {
          
          let startTimeMoment = moment.utc(item.sTime).tz(user.timezone);
          // if (startTimeMoment.isDST() && !isDST) {
          //   // move one hour backwards
          //   startTimeMoment = startTimeMoment.subtract(1, 'hours');
          // } else if (!startTimeMoment.isDST() && isDST) {
          //   // move one hour forwards
          //   startTimeMoment = startTimeMoment.add(1, 'hours');
          // }
          
          let endTimeMoment = moment.utc(item.eTime).tz(user.timezone);
          // if (endTimeMoment.isDST() && !isDST) {
          //   // move one hour backwards
          //   endTimeMoment = endTimeMoment.subtract(1, 'hours');
          // } else if (!endTimeMoment.isDST() && isDST) {
          //   // move one hour forwards
          //   endTimeMoment = endTimeMoment.add(1, 'hours');
          // }
          
          let startTime = startTimeMoment.format('HH:mm');
          let endTime = endTimeMoment.format('HH:mm');
          
          slots.push({
            slotId: item.slotId,
            date: item.date,
            description: `${startTime} - ${endTime}`,
            sTime: item.sTime,
            eTime: item.eTime,
            formatted: {
              date: moment.utc(item.sTime).tz(user.timezone).format('MMMM D, YYYY'),
              month: moment.utc(item.sTime).tz(user.timezone).format('MMMM'),
              dayYear: moment.utc(item.sTime).tz(user.timezone).format('D, YYYY'),
              dayOfWeek: moment.tz().weekday(moment.utc(item.sTime).tz(user.timezone).weekday()).format('dddd'),
              expired: moment.utc(item.eTime).tz(user.timezone).valueOf() - moment.tz().valueOf() < 0 ? true : false
            },
            previewUsers: item.userId ? [{
              slotId: item.slotId,
              userId: item.userId,
              name: item.name,
              phone: item.phone,
              slotNo: item.slotNo,
              canceled: item.canceled,
              gender: item.gender,
            }] : [],
            users: [{
              slotId: item.slotId,
              userId: item.userId,
              name: item.name,
              phone: item.phone,
              slotNo: item.slotNo,
              canceled: item.canceled,
              gender: item.gender,
            }],
          });
          
        } else {
          
          if (item.userId && slots[slotIndex].previewUsers.length < 3) {
            slots[slotIndex].previewUsers.push({
              slotId: item.slotId,
              userId: item.userId,
              name: item.name,
              phone: item.phone,
              slotNo: item.slotNo,
              canceled: item.canceled,
              gender: item.gender,
            });
          }
          
          slots[slotIndex].users.push({
            slotId: item.slotId,
            userId: item.userId,
            name: item.name,
            phone: item.phone,
            slotNo: item.slotNo,
            canceled: item.canceled,
            gender: item.gender,
          });
          
        }
        
      }
      
      slots = slots.map(slot => {
        slot.assignedUser = slot.users.find(userB => userB.userId === user.userId);
        slot.availableSlots = slot.users.filter(user => !user.userId);
        slot.takenSlots = slot.users.filter(user => user.userId);
        slot.full = slot.availableSlots.length === 0;
        slot.canceled = slot.users.filter(user => !user.canceled || user.canceled === 3).length === 0;
        // if (slot.assignedUser) {
        //   let index = slot.previewUsers.findIndex(previewUser => previewUser.userId === user.userId);
        //   if (index > -1) {
        //     slot.previewUsers = array_move(slot.previewUsers, index, 0);
        //   } else {
        //     if (slot.previewUsers.length === 3) {
        //       slot.previewUsers.pop();
        //     }
        //     slot.previewUsers.unshift({
        //       slotId: slot.assignedUser.slotId,
        //       userId: slot.assignedUser.userId,
        //       name: slot.assignedUser.name,
        //       phone: slot.assignedUser.phone,
        //       slotNo: slot.assignedUser.slotNo,
        //       canceled: slot.assignedUser.canceled,
        //       gender: slot.assignedUser.gender,
        //     });
        //   }
        // }
        return slot;
      });
      
      slots.sort((a, b) => {
        let an = Number(moment.utc(a.sTime).format('YYYY-MM-DD').split('-').join(''));
        let bn = Number(moment.utc(b.sTime).format('YYYY-MM-DD').split('-').join(''));
        let atn = Number(
          a.description.split(' - ').join('').split(':').join('')
        );
        let btn = Number(
          b.description.split(' - ').join('').split(':').join('')
        );
        if (an === bn) {
          return atn < btn ? -1 : atn > btn ? 1 : 0;
        } else {
          return an < bn ? -1 : 1;
        }
      });
      
      for (let i in slots) {
        if (moment.tz().diff(moment.utc(slots[i].sTime).tz(user.timezone), 'days') <= 0) {
          slots[i].scrollTarget = true;
          break;
        }
      }

      // slots = slots.filter(slot => {
      //   return slot.date >= new Date().toISOString().split('T')[0];
      // });

      setSlots(slots || []);
      
      if (responseSlot.status !== 'success') {
        throw Error(responseSlot.message);
      }
    } catch (err) {}
  };
  
  useEffect(() => {
    
    if (!location || !selectedDate) {
      return;
    }
    
    getSlots();
    
  }, [selectedDate, location, activeLocation, selectedGroupId, selectedMonthAndYear]);
  
  useEffect(() => {
    setResetScroll(true);
  }, [location])
  
  useEffect(() => {
    if (slots.length > 0) {
      if (resetScroll) {
        setTimeout(() => {
          let scrollTarget = document.querySelector('tr[scroll-target="true"]');
          if (scrollTarget) {
            bodyRef.current.scroll({
              top: scrollTarget.offsetTop,
              // behavior: 'smooth'
            });
          }
          setResetScroll(false);
        }, 10);
      } else {
        console.log('scrolling to currentScroll', currentScroll);
        bodyRef.current.scroll({
          top: currentScroll,
          // behavior: 'smooth'
        });
        setCurrentScroll(0);
      }
    }
  }, [slots]);
  
  // useEffect(() => {
    
  //   channel.unbind('update');
    
  //   channel.bind('update', (data) => {
  //     if (data.message === 'update') {
  //       getSlots({
  //         resetScroll: false
  //       });
  //     }
  //   });
    
  //   return () => {
  //     channel.unbind('update');
  //   }
    
  // }, []);
  
  
  // useEffect(() => {
  //   if (isMobile) {
  //     if (currentDateRef.current) {
  //       currentDateRef.current.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'start'
  //       });
  //     }
  //     setTimeout(() => {
  //       if (currentDateRef.current) {
  //         currentDateRef.current.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'start'
  //         });
  //       }
  //     }, 600)
  //   }
  // }, [currentDateRef.current]);
  
  // useEffect(() => {
  //   currentDateRef.current = null;
  // }, [selectedMonthAndYear, selectedLocationId, selectedGroupId]);
  
  // useEffect(() => {
    
  //   channel.unbind('update');
    
  //   channel.bind('update', (data) => {
  //     if (data.message === 'update') {
  //       getSlots();
  //     }
  //   });
    
  //   return () => {
  //     channel.unbind('update');
  //   };
  // }, []);
  
  return (
    <Table
      striped
      hover
      responsive="sm"
    >
      <thead>
      </thead>
      <tbody>
        {slots.map((slot, i) => {
          return (
            <>
              {(!slots[i - 1] || slot.formatted.date !== slots[i - 1].formatted.date) &&
                <tr
                  key={`${slot.slotId}-date`}
                  scroll-target={String(Boolean(slot.scrollTarget))}
                >
                  <td colSpan="3" className="align-middle date">
                    <div className="font-weight-bold" style={{
                      fontSize: '17px'
                    }}>{`${localization[slot.formatted.dayOfWeek]}, ${localization[slot.formatted.month]} ${slot.formatted.dayYear}`}</div>
                  </td>
                </tr>
              }
              <SlotExpanded
                key={slot.slotId}
                slots={slots} 
                slot={slot} 
                i={i} 
                user={user} 
                getSlots={getSlots}
                cancel={cancel} 
                removeAfterExpiration={removeAfterExpiration}
                reserve={reserve}
                callMouseUp={callMouseUp}
                callMouseDown={callMouseDown}
                selectedDate={selectedDate}
                groupUsers={groupUsers}
              />
            </>
          );
        })}
      </tbody>
    </Table>
  );
}

function SlotExpanded({
  slots,
  slot,
  i,
  user,
  getSlots,
  cancel,
  removeAfterExpiration,
  reserve,
  callMouseUp,
  callMouseDown,
  selectedDate,
  groupUsers
}) {
  
  let [isExpanded, setIsExpanded] = useState(false);
  
  let [previewUsers, setPreviewUsers] = useState(Array(slot.users.length).fill('').slice(0, 3));
  
  let [selectedUser, setSelectedUser] = useState(user.userId);
  let [selectUser, setSelectUser] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState([]);
  
  const requestSlot = async (slotId, userId) => {
    if ((user.admin === 1 || user.admin === 2 || user.admin === 3) && !userId) {
      setSelectUser(true);
      let sortedGroupUsers = groupUsers.filter(user => !user.deleted && user.state).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      let allowedUsers = [user, ...sortedGroupUsers];
      if (slot.takenSlots.length > 0) {
        allowedUsers = allowedUsers.filter(user => !slot.takenSlots.find(userB => userB.userId === user.userId));
      }
      setAllowedUsers(allowedUsers);
      setSelectedUser(allowedUsers[0].userId);
    } else if (userId) {
      setSelectUser(false);
      await reserve(slotId, userId);
    } else {
      await reserve(slotId, user.userId);
    }
  };
  
  return (
    <>
      <tr
        className=""
        key={slot.slotId}
        onClick={e => setIsExpanded(isExpanded => !isExpanded)}
        style={{
          borderWidth: '1px 0px',
          // boxShadow: slot.assignedUser ? 'inset 6px 0px 0px -3px #fd5458' : 'initial'
          boxShadow: slot.assignedUser ? 'rgb(72 175 86) 7px 0px 0px -3px inset' : 'initial'
        }}
      >
        <td className="align-middle pe-0" style={{
          width: '100px'
        }}>
          <div className="home-slot-time font-weight-bold">
            {slot.description}
          </div>
        </td>
        <td className="align-middle">
          <div className="d-flex gap-2">
            {previewUsers.map((_, i) => {
              if (slot.previewUsers[i]) {
                if (slot.previewUsers[i].gender == 2) {
                  return (
                    <img key={i} className="rounded-circle" width="42" height="42" src={woman} alt="" style={{
                      // filter: slot.assignedUser && i === 0 ? 'hue-rotate(-451deg)' : 'initial'
                    }} /> 
                  );
                } else {
                  return (
                    <img key={i} className="rounded-circle" width="42" height="42" src={man} alt="" style={{
                      // filter: slot.assignedUser && i === 0 ? 'hue-rotate(-451deg)' : 'initial'
                    }} /> 
                  );
                }
              } else {
                return (
                  <div key={i} className="border rounded-circle" style={{
                    width: '42px',
                    height: '42px'
                  }}></div>
                );
              }
            })}
          </div>
        </td>
        <td className="align-middle ps-0" style={{
          width: '48px'
        }}>
          <span 
            className="d-flex align-items-center justify-items-center fw-bold mx-auto"
          >
            {slot.formatted.expired ?
              <FcLock className="" size="2.3rem" />
            : 
              <span>
                {slot.canceled ?
                  <FcCancel className="text-danger" size="2.3rem" />
                : 
                  <slot>
                    {slot.full ? 
                      <IoIosArrowDropdown size="2.3rem" />
                    : 
                      <FcPlus className="text-success" size="2.3rem" />
                    }
                  </slot>
                }
              </span>
            }
          </span>
        </td>
      </tr>
      {isExpanded &&
        <tr>
          <td colSpan="3 align-middle">
            <div className="mb-2 me-2" style={{
              textAlign: 'right'
            }}>
              {slot.canceled ?
                <i>{localization['Canceled']}</i>
              :
                <div>
                  {slot.formatted.expired ?
                    <i>{localization['Expired']}</i>
                  :
                    <div>
                      {slot.full ?
                        <i>{localization['Full']}</i>
                      :
                        <i>{localization['Available']}: {slot.availableSlots.length}</i>
                      }
                    </div>
                  }
                </div>
              }
            </div>
            {slot.users.map((u, i) => {
            //console.log('u', u)
            let hasBooked = false;
            let tempValue = Object.values(slot.users);
            let obj = tempValue.find((o) => o.userId === user.userId);
            if (obj) {
              hasBooked = true;
            }
            // if (u.canceled && u.canceled != 3) {
            //   return (
            //     <div
            //       key={u.slotId}
            //       className="btn-register-wrapper"
            //     >
            //       <div className="slot-status-text">
            //         {localization['Canceled']}
            //       </div>
            //     </div>
            //   );
            // } else if (u.userId === user.userId) {
            if (u.userId === user.userId) {
              return (
                <div
                  key={`${u.slotId}-${u.userId}`}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Form.Label
                    //className="d-inline-block mx-2"
                    //style={{ 'verticalAlign': 'top' }}
                  >
                    <span
                      className={
                        u.gender == 2 ? 'female-color' : 'male-color'
                      }
                    >
                      {localization['You']}
                    </span>
                  </Form.Label>
                  {!slot.formatted.expired ? (
                    <Button
                      variant="outline-danger"
                      className="mx-2 mb-2"
                      onClick={(e) =>
                        cancel(
                          e,
                          u.slotId,
                          slot.description,
                          moment.utc(slot.sTime).tz(user.timezone).format('YYYY-MM-DD'),
                          slot.sTime,
                          u.userId
                        )
                      }
                    >
                      {localization['Cancel']}
                    </Button>
                  ) : (
                    <div>
                      {/*
                      <div className="slot-status-text">
                        <span className="mx-2">
                          {localization['Expired']}
                        </span>
                      </div>
                      */}
                      {/*
                      {user.admin != 0 && (
                        <Button
                          variant="outline-danger"
                          className="mx-2 mb-2"
                          onClick={(e) =>
                            removeAfterExpiration(u.slotId)
                          }
                        >
                          {localization['Remove']}
                        </Button>
                      )}
                      */}
                    </div>
                  )}
                </div>
              );
            } else if (!u.userId) {
              return (
                <div key={u.slotId} className="btn-register-wrapper justify-content-end w-100" style={{maxWidth: 'unset'}}>
                  <div className="text-end">
                    {(!hasBooked || user.admin !== 0) && (
                      <>
                        {!slot.formatted.expired && !slot.canceled ? (
                          <>
                          {selectUser ?
                            <>
                              <Form.Select
                                className="mx-2 mb-2 w-auto"
                                style={{
                                  minWidth: 'auto'
                                }}
                                onChange={(e) => {
                                  setSelectedUser(e.target.value);
                                }}
                              >
                                {allowedUsers.map(user => {
                                  return (
                                    <option
                                      key={user.userId}
                                      value={user.userId}
                                      selected={Number(selectedUser) === user.userId}
                                    >
                                      {user.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <Button
                                className="mx-2 mb-2 btn-green"
                                variant="primary"
                                onClick={(e) => requestSlot(u.slotId, Number(selectedUser))}
                              >
                                {localization['Confirm Request']}
                              </Button>
                            </>
                            :
                              <>
                                <Form.Label
                                  // className="d-inline-block mx-2"
                                  // style={{ 'verticalAlign': 'top' }}
                                >
                                  &nbsp;
                                </Form.Label>
                                <Button
                                  className="mx-2 mb-2 btn-green"
                                  variant="primary"
                                  onClick={(e) => requestSlot(u.slotId)}
                                >
                                  {localization['Request']}
                                </Button>
                              </>
                            }
                          </>
                        ) : (
                          <>
                            {/*
                            <div className="home-text-icon-wrapper slot-status-text">
                              {localization['Expired']}
                            </div>
                            */}
                            {/*
                            {user.admin != 0 && (
                              <div className="home-expired-remove">
                                <Button
                                  variant="outline-danger"
                                  className="mx-2 mb-2"
                                  onClick={(e) =>
                                    removeAfterExpiration(u.slotId)
                                  }
                                >
                                  {localization['Remove']}
                                </Button>
                              </div>
                            )}
                            */}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={u.slotId}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Label
                      className=""
                      disabled
                    >
                      <a
                        className="call-button"
                        href={`tel:${u.phone}`}
                        onMouseUp={(e) => callMouseUp(e)}
                        onMouseDown={(e) => callMouseDown(e)}
                        onClick={(e) => e.preventDefault()}
                      >
                        <span
                          className={
                            u.gender == 2
                              ? 'female-color'
                              : 'male-color'
                          }
                        >
                          {u.name}
                        </span>
                      </a>
                    </Form.Label>
                    {!slot.formatted.expired && user.admin !== 0 && (
                      <Button
                        variant="outline-danger mx-2 mb-2"
                        onClick={(e) =>
                          cancel(
                            e,
                            u.slotId,
                            slot.description,
                            moment.utc(slot.sTime).tz(user.timezone).format('YYYY-MM-DD'),
                            slot.sTime,
                            u.userId
                          )
                        }
                      >
                        {localization['Cancel']}
                      </Button>
                    )}
                  </div>
                </div>
              );
            }
          })}
          </td>
        </tr>
      }
    </>
  );
}

export default Home;
